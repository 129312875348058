import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../AuthContext'

function RoutoraWebDemo(props) {

    const history = useHistory();

    const { currentUser, currentUserTier } = useContext(AuthContext);

    function goSignUp() {
        if (currentUser) {
            if (currentUserTier == 2) {
                history.push('/playground')
            } else {
                history.push('/pricing')
            }
        } else {
            history.push('/signup')
        }
    }

  return (
    <section className="relative">

        {!props.isWebIntro && 
            <div>
            <div className="absolute inset-0 bg-gradient-to-t from-white to-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
            <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
            </div>
        }

        <div id="web-intro" className="relative max-w-6xl mx-auto px-4 sm:px-6">

            <div className="max-w-3xl mx-auto text-center pb-4 md:pb-10 pt-20">
                <h1 className="h2 mb-4 text-myblue-900">Routora on Web</h1>
                <p className="md:text-xl text-lg text-gray-600 mb-7">Create your optimized route in less than 2 minutes. Spend less time on the road and more time for what's important with the Routora Web Route Planner.</p>
                <div>
                    <a onClick={goSignUp} className="btn cursor-pointer text-white font-semibold bg-blue-500 hover:bg-blue-600 w-1/2 mb-4 md:w-64 md:mb-0 py-4">Get Started</a>
                </div>
            </div>

            {/* looping demo video auto play on scroll */}
            <div className="video-container mb-10">

                <video className="w-full rounded border" autoPlay loop muted>
                    <source src="/routora-web-demo-muted-compressed.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                
            </div>

        </div>

    </section>
  );
}

export default RoutoraWebDemo;
